import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"

const PeterLehrkeStoryPage = () => (

    <Layout pageClass="story-page">
    <SEO title="Peter Lehrke - Pharmaceutical Technologies Ltd. - Story" />
    <main className="main">
        <div className="typography inner">
            <h1>Peter Lehrke - Pharmaceutical Technologies Ltd.</h1>          
            <p>I now feel like I am back in control of my business.</p>

            <p>Working with Dave has shown me the benefit of strategic thinking, rather than tactical reacting. When you are clear about why you are in business, and then what business you are really in, it becomes much easier to know how to achieve your business and personal goals. </p>

            <p>Dave has coached me to be disciplined about planning which helps me focus on the important few rather than the trivial many.  This makes me much more effective at leveraging my time on the things that give me the most gain for both the business and my personal life.</p>

            <p>Always a positive support Dave's advice is both reassuring and empowering. And his generous use of his massive network of connections has been an added bonus.  I strongly recommend a discovery session with Dave that will uncover some gems for you as well as helping you solve that troubling problem in your business.</p>
        </div>
    </main>
  </Layout>

)


export default PeterLehrkeStoryPage